<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <div class="grid grid-cols-2 mx-4 gap-2 gap-y-6 md:grid-cols-4 md:mx-0">
    <div class="flex flex-col items-center">
      <NuxtImg src="/icons/award.svg" alt="Award" class="h-16 w-16" />
      <p class="text-md pt-2 text-center text-black">
        {{ t('general.before_footer.most_liked_eshop') }}
      </p>
      <p class="text-center text-xs">
        {{ t('general.before_footer.most_liked_eshop_text') }}
      </p>
    </div>
    <div class="flex flex-col items-center">
      <NuxtImg src="/icons/box.svg" alt="Box" class="h-16 w-16" />
      <p class="text-md pt-2 text-center text-black">
        {{ t('general.before_footer.free_shipping') }}
      </p>
      <p class="text-center text-xs">
        {{ t('general.before_footer.free_shipping_text') }}
      </p>
    </div>
    <div class="flex flex-col items-center">
      <NuxtImg src="/icons/rocket.svg" alt="Rocket" class="h-16 w-16" />
      <p class="text-md pt-2 text-center text-black">
        {{ t('general.before_footer.we_will_send_today') }}
      </p>
      <p class="text-center text-xs">
        {{ t('general.before_footer.we_will_send_today_text') }}
      </p>
    </div>
    <div class="flex flex-col items-center">
      <NuxtImg src="/icons/return-box.svg" alt="Return box" class="h-16 w-16" />
      <p class="text-md pt-2 text-center text-black">
        {{ t('general.before_footer.100_day_to_return_good') }}
      </p>
      <p class="text-center text-xs">
        {{ t('general.before_footer.100_day_to_return_good_text') }}
      </p>
    </div>
  </div>
  <div class="md:mx0goo mx-4 flex items-center justify-center">
    <NuxtLinkLocale to="/collections/best-sellers" class="max-w-720px w-full pt-10">
      <NuxtImg src="/webcontent/mascot.png" class="w-full" />
    </NuxtLinkLocale>
  </div>
</template>
